@import '../../../../theme/breakpoints';

.wrapper {
  color: var(--color-sem-text-neutral-bold);
  font-weight: 100;

  ul {
    display: flex;
    flex-flow: row nowrap;
    flex-wrap: wrap;
    gap: var(--space-com-cta-margin);
    padding-left: 0;
    width: fit-content;

    li {
      list-style: none;
    }
  }

  &.left {
    margin-left: 0;
  }

  &.center {
    margin-right: auto;
  }
}

header {
  .navigator-only {
    @media #{$large} {
      padding-right: var(--space-sem-margin-medium);
    }
  }
}
