$cta-color: --color-sem-cta;
$social-color: --color-ref-socials;

.cta-base {
  align-items: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius-com-cta-radius);
  display: inline-flex;
  font-family: inherit;
  font-size: var(--text-typeset-font-size-m);
  font-style: normal;
  font-weight: var(--text-typeset-font-weights-normal-600);
  gap: 4px;
  height: var(--size-sem-cta-height-medium);
  justify-content: center;
  line-height: var(--size-ref-7);
  padding: 0 var(--space-com-cta-padding);
  text-decoration: none;

  &:focus-visible {
    outline: 2px solid var(--color-sem-focus-default);
    outline-offset: 2px;
  }

  &:disabled {
    border: 1px solid transparent;
  }

  svg {
    height: var(--size-sem-icon-medium);
    width: var(--size-sem-icon-medium);
  }
}

.round {
  padding: 0;
  width: var(--size-sem-cta-height-medium);
}

.primary {
  background-color: var(#{$cta-color}-bg-primary-default);
  color: var(#{$cta-color}-fg-primary-default);

  &:disabled {
    background-color: var(#{$cta-color}-bg-primary-disabled);
    color: var(#{$cta-color}-fg-primary-disabled);
  }

  &:not(:disabled, :active):hover {
    background-color: var(#{$cta-color}-bg-primary-hover);
    border: 1px solid var(#{$cta-color}-bg-primary-hover);
    color: var(#{$cta-color}-fg-primary-hover);
  }

  &:not(:disabled):active {
    background-color: var(#{$cta-color}-bg-primary-clicked);
    color: var(#{$cta-color}-fg-primary-clicked);
  }
}

.primary-inverted {
  background-color: var(#{$cta-color}-bg-primary-inverted-default);
  color: var(#{$cta-color}-fg-primary-inverted-default);

  &:disabled {
    background-color: var(#{$cta-color}-bg-primary-inverted-disabled);
    color: var(#{$cta-color}-fg-primary-inverted-disabled);
  }

  &:not(:disabled, :active):hover {
    background-color: var(#{$cta-color}-bg-primary-inverted-hover);
    border: 1px solid var(#{$cta-color}-bg-primary-inverted-hover);
  }

  &:not(:disabled):active {
    background-color: var(#{$cta-color}-bg-primary-inverted-clicked);
    color: var(#{$cta-color}-fg-primary-inverted-clicked);
  }
}

.secondary {
  background-color: var(#{$cta-color}-bg-secondary-default);
  border: 1px solid var(#{$cta-color}-sg-secondary-default);
  color: var(#{$cta-color}-fg-secondary-default);

  &:disabled {
    background-color: var(#{$cta-color}-bg-secondary-disabled);
    color: var(#{$cta-color}-fg-secondary-disabled);
  }

  &:not(:disabled, :active):hover {
    background-color: var(#{$cta-color}-bg-secondary-hover);
    border-color: var(#{$cta-color}-sg-secondary-hover);
    color: var(#{$cta-color}-fg-secondary-hover);
  }

  &:not(:disabled):active {
    background-color: var(#{$cta-color}-bg-secondary-clicked);
    border: 1px solid transparent;
    color: var(#{$cta-color}-fg-secondary-clicked);
  }
}

.tertiary {
  background-color: var(#{$cta-color}-bg-tertiary);
  color: var(#{$cta-color}-fg-tertiary);

  &:disabled {
    background-color: var(#{$cta-color}-bg-tertiary-disabled);
    color: var(#{$cta-color}-fg-tertiary-disabled);
  }

  &:not(:disabled, :active):hover {
    background-color: var(#{$cta-color}-bg-tertiary-hover);
    border: 1px solid var(#{$cta-color}-sg-tertiary-hover);
    color: var(#{$cta-color}-fg-tertiary-hover);
  }

  &:not(:disabled):active {
    background-color: var(#{$cta-color}-bg-tertiary-clicked);
    border: 1px solid transparent;
    color: var(#{$cta-color}-fg-tertiary-clicked);
  }
}

.neutral {
  background-color: var(#{$cta-color}-bg-neutral-default);
  border: 1px solid transparent;
  color: var(#{$cta-color}-fg-neutral-default);

  &:disabled {
    background-color: var(#{$cta-color}-bg-neutral-disabled);
    color: var(#{$cta-color}-fg-neutral-disabled);
  }

  &:not(:disabled, :active):hover {
    background-color: var(#{$cta-color}-bg-neutral-hover);
    color: var(#{$cta-color}-fg-neutral-hover);
  }

  &:not(:disabled):active {
    background-color: var(#{$cta-color}-bg-neutral-clicked);
    color: var(#{$cta-color}-fg-neutral-clicked);
  }
}

.neutral-inverted {
  background-color: var(#{$cta-color}-bg-neutral-inverted-default);
  border: 1px solid transparent;
  color: var(#{$cta-color}-fg-neutral-inverted-default);

  &:disabled {
    background-color: var(#{$cta-color}-bg-neutral-inverted-disabled);
    color: var(#{$cta-color}-fg-neutral-inverted-disabled);
  }

  &:not(:disabled, :active):hover {
    background-color: var(#{$cta-color}-bg-neutral-inverted-hover);
    border: 1px solid var(#{$cta-color}-sg-neutral-inverted-hover);
    color: var(#{$cta-color}-fg-neutral-inverted-hover);
  }

  &:not(:disabled):active {
    background-color: var(#{$cta-color}-bg-neutral-inverted-clicked);
    color: var(#{$cta-color}-fg-neutral-inverted-clicked);
  }
}

.white {
  background-color: var(#{$cta-color}-bg-white-default);
  border: 1px solid transparent;
  color: var(#{$cta-color}-fg-white-default);

  &:disabled {
    background-color: var(#{$cta-color}-bg-white-disabled);
    color: var(#{$cta-color}-fg-white-disabled);
  }

  &:not(:disabled, :active):hover {
    background-color: var(#{$cta-color}-bg-white-hover);
    color: var(#{$cta-color}-fg-white-hover);
  }

  &:not(:disabled):active {
    background-color: var(#{$cta-color}-bg-white-clicked);
    color: var(#{$cta-color}-fg-white-clicked);
  }
}

.black {
  background-color: var(#{$cta-color}-bg-black-default);
  border: 1px solid transparent;
  color: var(#{$cta-color}-fg-black-default);

  &:disabled {
    background-color: var(#{$cta-color}-bg-black-disabled);
    color: var(#{$cta-color}-fg-black-disabled);
  }

  &:not(:disabled, :active):hover {
    background-color: var(#{$cta-color}-bg-black-hover);
    border: 1px solid var(--color-sem-cta-sg-social-hover);
    color: var(#{$cta-color}-fg-black-hover);
  }

  &:not(:disabled):active {
    background-color: var(#{$cta-color}-bg-black-clicked);
    color: var(#{$cta-color}-fg-black-clicked);
  }
}

.count {
  font-weight: var(--text-typeset-font-weights-normal-600);
}

.social {
  color: var(--color-ref-neutral-0);

  &:disabled {
    background-color: var(#{$cta-color}-bg-socials-disabled);
    color: var(#{$cta-color}-fg-socials-disabled);
  }

  &:not(:disabled, :active):hover {
    background: var(#{$cta-color}-bg-socials-hover);
    border: 1px solid var(#{$cta-color}-sg-social-hover);
    color: var(#{$cta-color}-fg-socials-hover);
  }

  &:not(:disabled):active {
    background-color: var(#{$cta-color}-bg-socials-clicked);
    color: var(#{$cta-color}-fg-socials-clicked);
  }
}

.facebook {
  background-color: var(#{$social-color}-facebook);
}

.twitter {
  background-color: var(#{$social-color}-twitter);
}

.instagram {
  background-color: var(#{$social-color}-instagram);
}

.snapchat {
  background-color: var(#{$social-color}-snapchat);
  color: var(--color-ref-neutral-1000);
}

.tiktok {
  background-color: var(#{$social-color}-tiktok);
  color: var(--color-ref-neutral-1000);
}

.nextdoor {
  background-color: var(#{$social-color}-nextdoor);
}

.whatsapp {
  background-color: var(#{$social-color}-whatsapp);
}

.pinterest {
  background-color: var(#{$social-color}-pinterest);
}

.x {
  background-color: var(#{$social-color}-x);
}

.youtube {
  background-color: var(#{$social-color}-youtube);
}
