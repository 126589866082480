.jw-player-container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-sem-padding-small);
  margin-top: var(--space-ref-6);
  width: 100%;

  > div {
    aspect-ratio: 16 / 9;
    position: relative;
  }

  > .poster > div {
    height: 100%;
    width: 100%;
  }
}

.poster-image {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}

.headline {
  background: var(--color-ref-neutral-900);
  box-sizing: border-box;
  color: var(--color-ref-neutral-0);
  display: block;
  font-size: var(--typography-button-sm-600-font-size);
  font-weight: var(--text-typeset-font-weights-normal-600);
  padding: 18px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
