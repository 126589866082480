@import '../../../../theme/breakpoints';

.masthead-webstrip {
  display: flex;
  height: 33px;
  justify-content: center;
  margin-left: 0;
  overflow: hidden;
  user-select: auto;
}

.masthead-webstrip img {
  display: inline-block;
  height: 31px;
  vertical-align: middle;
}

@media #{$large} {
  .masthead-webstrip {
    margin-left: 0;
    max-width: var(--wrapper-l);
  }
}

@media #{$xlarge} {
  .masthead-webstrip {
    margin: 0 auto;
    max-width: var(--wrapper-xl);
  }
}
