@import '../../../../theme/breakpoints';

.placeholder {
  opacity: 1;
  padding: 0 0 var(--size-ref-4);
  transition: opacity 0.3s ease-in-out;
}

.fade-out {
  opacity: 0;
}

.mpu {
  height: calc(
    250px + var(--size-ref-10) + var(--size-ref-4)
  ); // height is calculated as base height + label + padding-bottom

  width: 100%;
}

.mid {
  height: auto;
  width: 100%;
  @media #{$medium} {
    aspect-ratio: 16/17;
    margin-bottom: var(--size-ref-7);
  }
  @media #{$medium} {
    height: calc(
      250px + var(--size-ref-10) + var(--size-ref-4)
    ); // height is calculated as base height + label + padding-bottom
  }
}

.outstream {
  height: auto;
  width: 100%;
  @media #{$medium} {
    aspect-ratio: 16/15;
    max-height: 369px;
  }
  @media #{$medium} {
    height: calc(
      444px + var(--size-ref-10) + var(--size-ref-4)
    ); // height is calculated as base height + label + padding-bottom
  }
}

.rhc {
  height: auto;
  width: 100%;
  @media #{$large} {
    height: calc(
      600px + var(--size-ref-10) + var(--size-ref-7) + var(--size-ref-7)
    ); // height is calculated as base height + label + margin-bottom for both CommercialBoxStyle and CommercialBoxChildStyle
  }
}
