@import '../../../../theme/breakpoints';

.text-link {
  align-items: center;
  color: var(--color-sem-link-primary-default);
  font-size: var(--text-typeset-font-size-m, 16px);
  font-weight: var(--text-typeset-font-weights-normal-600);
  letter-spacing: var(--text-typeset-letter-spacing-normal, 0);
  pointer-events: none;
  text-decoration: var(--text-typeset-text-decoration-none);
}

.nav-curtain-submenu-item {
  color: var(--color-sem-link-neutral-default);
}

// for breadcrumbs
main nav ul li {
  .text-link {
    color: var(--color-sem-icon-neutral-default);
    text-transform: capitalize;

    :not(:first-of-type) {
      :hover {
        text-decoration: var(--text-typeset-text-decoration-underline);
      }
    }
  }
}

.text-link.enabled {
  pointer-events: initial;
}

.text-link.disabled {
  color: var(--color-sem-text-neutral-disabled);
}

.text-link:hover {
  text-decoration: var(--text-typeset-text-decoration-underline);
}
