.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: calc(100vw - 4 * 20px);
  width: 100%;
}

.container #primis-placeholder.primisslate {
  max-height: 432px;
}

.container #primis-placeholder.primisslate * {
  max-height: 432px;
}

.container > div {
  aspect-ratio: 16 / 8;
  max-height: 432px;
  position: relative;
  width: inherit;
}

@media (max-width: 728px) {
  .container > div {
    aspect-ratio: 16 / 9;
  }
}
