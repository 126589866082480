@keyframes pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.blinker {
  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 1500ms;
    animation-name: pulse;
    animation-timing-function: ease-in-out;
  }

  border-radius: 50%;
  content: '';
  display: block;
  height: 11px;
  width: 11px;
}

.default {
  background-color: var(--color-sem-icon-neutral-inverted);
}

.red {
  background-color: #e72b1d;
}
