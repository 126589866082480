@import '../../../../theme/breakpoints';

.affiliate-content-container {
  border-bottom: 1px solid var(--color-ref-neutral-500);
  border-top: 1px solid var(--color-ref-neutral-500);
  color: var(--color-ref-neutral-900);
  display: grid;
  font-family: inherit;
  gap: var(--space-sem-gap-small);
  grid-template-columns: 100%;
  grid-template-rows: auto;
  padding: var(--space-sem-padding-medium) 0;

  .section-container {
    display: grid;
    grid-template-columns: 100%;

    .section-content {
      background-color: var(--color-ref-neutral-100);
      display: grid;
      grid-template-columns: 100%;
      padding: var(--space-sem-padding-none) var(--space-sem-padding-small);
    }

    .content-pros .section-content,
    .content-cons .section-content {
      padding: var(--space-sem-padding-none);
    }

    &.content-details .section-content {
      background-color: none;
      padding: var(--space-sem-padding-none);

      > div {
        border: 1px solid var(--color-ref-neutral-300);
        padding: var(--space-sem-padding-medium) var(--space-sem-padding-none);
      }
    }

    .section-header {
      font-size: var(--text-typeset-font-size-xl);
      font-weight: var(--text-typeset-font-weights-normal-700);
      padding: var(--space-sem-padding-small) 0;

      &.collapsible-header {
        background-color: unset;
        border: none;
        border-bottom: 1px solid var(--color-ref-neutral-300);
        cursor: pointer;
        position: relative;
        text-align: left;

        &::after {
          background-image: url('/icons/icon-accordion-arrows.svg');
          background-position-x: var(--size-sem-icon-medium);
          content: ' ';
          display: inline-flex;
          height: var(--size-sem-icon-medium);
          position: absolute;
          right: 0;
          width: var(--size-sem-icon-medium);
        }

        &.collapse {
          &::after {
            background-position-x: 0;
          }

          + .section-content {
            display: none;
          }
        }
      }
    }
  }

  .content-title {
    font-size: var(--text-typeset-font-size-2xl);
    font-weight: var(--text-typeset-font-weights-normal-700);
  }

  .content-details {
    display: grid;
    font-size: var(--text-typeset-font-size-m);
    font-weight: var(--text-typeset-font-weights-normal-400);
    grid-template-rows: auto;

    .content-image {
      overflow: hidden;
      text-align: center;
      width: 100%;

      img {
        height: 162px;
        width: auto;
      }
    }

    .content-price-details {
      border-top-width: 0;
      display: flex;
      flex-flow: column nowrap;
      gap: var(--space-sem-gap-medium);
      justify-content: center;
      text-align: center;

      .content-was-price {
        font-size: var(--text-typeset-font-size-m);
        font-weight: var(--text-typeset-font-weights-normal-700);
        text-decoration: line-through;
      }

      .content-price {
        font-size: var(--text-typeset-font-size-3xl);
        font-weight: var(--text-typeset-font-weights-normal-700);
      }

      .content-provider {
        font-size: var(--text-typeset-font-size-3xl);
        font-weight: var(--text-typeset-font-weights-normal-400);
      }

      .content-buy-btn {
        background-color: var(--color-ref-affiliate-500);
        border-radius: var(--border-radius-sem-cta-max);
        color: inherit;
        font-size: var(--typography-button-sm-600-font-size);
        font-weight: var(--text-typeset-font-weights-normal-600);
        margin: 0 auto;
        min-width: 192px;
        padding: var(--space-com-cta-padding);
        text-decoration: none;

        &:hover,
        &:visited {
          color: var(--color-ref-neutral-900);
          text-decoration: none;
        }
      }
    }

    @media #{$large} {
      &.has-content-img {
        .section-content {
          grid-template-columns: 50% 50%;

          .content-price-details {
            border-left-width: 0;
          }
        }
      }

      .content-image img {
        height: auto;
        width: calc(100% - 20px);
      }
    }
  }

  .content-pros,
  .content-cons {
    padding: var(--space-sem-padding-xsmall) var(--space-sem-padding-none);

    .section-header {
      padding: var(--space-sem-padding-xsmall) var(--space-sem-padding-none);
    }

    &::before {
      font-size: var(--text-typeset-font-size-xl);
      font-weight: var(--text-typeset-font-weights-normal-700);
      line-height: var(--text-typeset-line-heights-l);
    }

    ul {
      margin-top: var(--space-ref-3);
      padding: var(--space-sem-padding-none);

      > li {
        list-style: none;
      }
    }

    > .section-content > p,
    ul > li {
      background-position-x: 0;
      background-position-y: 1px;
      background-repeat: no-repeat;
      margin: var(--space-ref-2) 0;
      padding-left: var(--space-ref-9);
    }
  }

  .content-pros {
    .section-content > p,
    ul > li {
      background-image: url('/icons/icon-circle-tick.svg');
    }
  }

  .content-cons {
    .section-content > p,
    ul > li {
      background-image: url('/icons/icon-circle-cross.svg');
    }
  }
}
